exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-detailed-info-js": () => import("./../../../src/pages/detailedInfo.js" /* webpackChunkName: "component---src-pages-detailed-info-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-screensaver-js": () => import("./../../../src/pages/screensaver.js" /* webpackChunkName: "component---src-pages-screensaver-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

